import React from 'react'
import { Container } from 'react-bootstrap'
import transport1 from '../../images/campus/transport/resized-image-Promo (1).jpeg'
import transport2 from '../../images/campus/transport/resized-image-Promo.jpeg'
import { useEffect } from 'react'
import AOS from 'aos';
import Head from '../Head';


function Transportation() {

  useEffect(() => {
    AOS.init({
      duration : 1500
    });
    AOS.refresh();

  }, []);

  return (
    <div className='overflow-hidden '>
      <Head title="Transportation"/>
      <Container className="px-5" >
        <header className="sectionHeader halfBorder mb-5">Transportation</header>
        <div className='mb-5'>
          <p className="sectionContent">
              The College has adequate transport facilities for both boys and girls to commute from Hubli to College premises.
          </p>
        </div>
        <div className="gallery d-flex flex-wrap"> 
          <figure data-aos="fade-up-right">
              <img fluid  src={transport1} alt="transport" />
          </figure>   
          <figure data-aos="fade-up-left">
              <img fluid  src={transport2} alt="transport" />
          </figure>   
        </div>
          
      </Container>

    </div>
  )
}

export default Transportation