import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from "../components/Layout"
import TransportComponent from "../components/campus/Transportation"

function Transport() {
    

  return (
    <div className='overflow-hidden '>
        <Layout>

            <Container className="px-5">
                <div style={{marginTop:'6rem'}}>
                    <TransportComponent />
                </div>
            </Container>
        </Layout>
    </div>
  )
}

export default Transport